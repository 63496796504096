import React, { useState } from 'react'
import './styles.scss'
import Logo from '../../Assets/Images/logo.png'
const Header = () => {
  const [mobileNav, setMobileNav] = useState(false)
  const navData = [
    {
      name: "Home",
      route: 'home'
    },
    {
      name: "Our Story",
      route: 'about'
    },
    {
      name: "Our Services",
      route: 'services'
    },
    {
      name: "Contact Us",
      route: 'contact'
    },
  ]
  return (
    <header className='header-container'>
      <div className="container">
        <div className="header-inner">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <nav className="nav">
            {
              navData.map((nd, index) => {
                return <a href={`#${nd.route}`} className="nav-item">
                  {nd.name}
                </a>
              })
            }
          </nav>
          <nav className="mobile-nav">
            <div className="mobile-nav-icon" onClick={()=> setMobileNav(true)}>
              <i class="fa-solid fa-bars"></i>
            </div>
            <div className={`mobile-nav-body ${mobileNav? 'mobile-nav-on' :'mobile-nav-off'}`}>
              <span className="mobile-cross" onClick={()=> setMobileNav(false)}>
              <i class="fa-solid fa-xmark"></i>
              </span>
              {
                navData.map((nd, index) => {
                  return <a href={`#${nd.route}`} className="nav-item" onClick={()=> setMobileNav(false)}>
                    {nd.name}
                  </a>
                })
              }
            </div>

          </nav>

        </div>
      </div>
    </header>
  )
}

export default Header