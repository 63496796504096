import './App.css';
import LandingPage from './Pages/LandingPage/LandingPage';
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import ReactGA from "react-ga4";
import IndexLayout from './Layout';

function App() {
  return (
    <div className="App">
      <IndexLayout>
      <LandingPage />

      </IndexLayout>
    </div>
  );
}

export default App;
