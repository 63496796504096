import React from 'react'
import './styles.scss'
import Flat from '../../Assets/Images/services/flat.png'
import House from '../../Assets/Images/services/house.png'
import Office from '../../Assets/Images/services/office.png'
import Room from '../../Assets/Images/services/room.png'
import Shutter from '../../Assets/Images/services/shutter.png'

const LandingServices = () => {
    const serviceData = [
        {
            name: "Rooms",
            image: Room
        },
        {
            name: "Flats",
            image: Flat
        },
        {
            name: "Shutter",
            image: Shutter
        },
        {
            name: "Office",
            image: Office
        },
        {
            name: "House",
            image: House
        },
        {
            name: "Transport",
            image: Room
        },

    ]
    return (
        <div className='landing-services-container' id='services'>
            <div className="container">
                <div className="landing-services-inner">
                    <h1 className="section-header">Our Services</h1>
                    <p className="section-desc">
                        Bhadawal is destined to provide renting services across different sectors.
                        At present we are focusing on renting spaces which are hard to get by.</p>
                    <div className="landing-services-boxes">
                        {
                            serviceData.map((sd, index) => {
                                return <div className="service-box">
                                    <div className="service-image">
                                        <img src={sd.image} alt="" />
                                    </div>
                                    <div className="service-text">
                                        {sd.name}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingServices