import React from 'react'
import LandingAbout from '../../Components/LandingAbout/LandingAbout'
import LandingDrawing from '../../Components/LandingDrawing/LandingDrawing'
import LandingHero from '../../Components/LandingHero/LandingHero'
import LandingServices from '../../Components/LandingServices/LandingServices'
import './styles.scss'
const LandingPage = () => {
  return (
    <div className='landing-page-container'>
        <LandingHero />
        <LandingAbout />
        <LandingServices/>
        <LandingDrawing />
    </div>
  )
}

export default LandingPage