import React from 'react'
import './styles.scss'
import Playstore from '../../Assets/Images/Playstore.png'
import FooterLogo from '../../Assets/Images/wordLogo.png'
const Footer = () => {
    return (
        <div className='footer-container' id='contact'>
            <div className="container">
                <div className="footer-inner">
                    <div className="footer-left">
                        <div className="footer-logo">
                            <img src={FooterLogo} alt="" />
                        </div>
                        <h1 className="section-header">
                            DOWNLOAD BHADAWAL TODAY
                        </h1>
                        <p className="section-desc">
                            Get our android beta version now at playstore.
                        </p>
                        <div className="footer-buttons">

                            <a href="https://play.google.com/store/apps/details?id=com.bhdwl" target="_blank">
                                <img src={Playstore} alt="" />
                            </a>
                            <div className="footer-links">
                                <a href="https://facebook.com/bhadawalnepal" className="footer-link" target="_blank">
                                    <i class="fa-brands fa-facebook-f"></i>
                                </a>
                                <a href="https://instagram.com/bhadawalnepal" className="footer-link" target="_blank">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                                <a href="https://twitter.com/bhadawalnepal" className="footer-link" target="_blank">
                                    <i class="fa-brands fa-twitter"></i>
                                </a>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-bottom-text">Copyright © 2023 Bhadawal. All rights reserved.</div>
                </div>
            </div>
        </div>
    )
}

export default Footer