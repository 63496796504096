import React, { useEffect } from 'react'
import './styles.scss'
import Playstore from '../../Assets/Images/Playstore.png'
import Header from '../Header/Header'
import SmallIllustrations from '../../Assets/Images/smallIllustrations.jpg'
import TrafficIllustrations from '../../Assets/Images/trafficIllustrations.jpg'

const LandingHero = () => {
    const heroTextStatus = () => {
        const herotext = document.querySelector(".landing-hero-inner")
        const heroContainer = document.querySelector(".landing-hero-container")
        const mainNav = document.querySelector(".header-container")

        if (window.scrollY > 800) {
            herotext.style.display = "none"
            mainNav.style.display = "none"
            heroContainer.style.backgroundImage=`url(${TrafficIllustrations})`
        }
        else {
            herotext.style.display = "flex"
            mainNav.style.display = "flex"
            heroContainer.style.backgroundImage=`url(${SmallIllustrations})`


        }
    }
    useEffect(() => {
        // window.addEventListener('scroll', heroTextStatus)

        // return () => {
        //     window.removeEventListener('scroll', heroTextStatus)
        // }
    }, [])
    return (
        <section className='landing-hero-container' id='home'>
            <Header />
            <div className="container">
                <div className="landing-hero-inner">
                    <div className="landing-hero-text">
                        <h1 className='section-header'>A Renting  <br /> Platform In Nepal</h1>
                        <p className='section-desc'>Now you can find room for rents and more from the comfort of your home</p>
                    </div>
                    <div className="landing-hero-buttons">
                        <a href="https://play.google.com/store/apps/details?id=com.bhdwl" target="_blank">
                            <img src={Playstore} alt="" />
                        </a>
                        <a className="main-button" href='#about'>About Us</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingHero