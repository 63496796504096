import React from 'react'
import './styles.scss'
import LargeIllustrations from '../../Assets/Images/largeIllustrations.jpg'
const LandingDrawing = () => {
  return (
    <div className='landing-drawing-container'>
        <img src={LargeIllustrations} alt="" />
    </div>
  )
}

export default LandingDrawing