import React from 'react'
import './styles.scss'
import AboutIllustrations from '../../Assets/Images/aboutIllustrations.jpg'
const LandingAbout = () => {
    const aboutData = [
        {
            id: "1",
            icon: <i class="fa-brands fa-google-play"></i>,
            name: "Download"
        },
        {
            id: "2",
            icon: <i class="fa-solid fa-mobile-screen-button"></i>,
            name: "Create Profile"
        },
        {
            id: "3",
            icon: <i class="fa-solid fa-person-hiking"></i>,
            name: "Be a Bhadawal"
        }
    ]
    return (
        <div className='landing-about-container' id='about'>
            <div className="container">
                <div className="landing-about-outer">
                    <div className="landing-about-image">
                        <img src={AboutIllustrations} alt="" />
                    </div>
                <div className="landing-about-inner">
                    <div className="landing-about-text">
                        <h1 className="section-header">About Bhadawal</h1>
                        <p className='section-desc'>
                            Bhadawal is a mobile app which will primarily act as a
                            platform for home owners and rent seekers to find each other thereby,
                            completely cutting off the middlemen. We hope Bhadawal will continue to
                            grow and cover other renting spaces like transportation, equipments etc.</p>
                    </div>


                    <div className="landing-about-boxes">
                        {aboutData.map((ad, index) => {
                            return <div className="about-box" key={ad.id}>
                                <div className="about-box-icon">{ad.icon}</div>
                                <div className="about-box-title">{ad.name}</div>
                            </div>
                        })}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default LandingAbout