import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import './styles.scss'
const IndexLayout = (props) => {
  return (
    <div className='layout-container'>
        {props.children}
        <Footer />
    </div>
  )
}

export default IndexLayout